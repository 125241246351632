body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }


  .condition-content {
    padding: 1em;
    position: relative;
    -webkit-transition: border-color .3s;
    transition: border-color .3s;
  }

  .condition-field-outline {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
    background-color: white;
  }

  .condition-main {
    width: 100%;
    display: table;
  }

  .condition-row {
    display: table-row;
  }

  .condition-row>div {
    display: table-cell;
    padding: .5em 1em;
    vertical-align: middle;
}

.fa-times-thin:before {
	content: '\00d7';
}








.search-box,.search-wrapper {
}
.form-group {
  position: relative;
}
.close-icon {
  border:1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
}
.close-icon:after {
	content: "X";
	display: block;
	width: 15px;
	height: 15px;
	position: absolute;
	background-color: transparent;
	z-index:1;
	right: 35px;
	top: 0;
	bottom: 0;
	margin: auto;
	padding: 2px;
	text-align: center;
	color: grey;
	font-weight: 700;
	font-size: 14px;
	cursor: pointer;
}
.close-icon {
  position: absolute;
  top: 38px;
  right: 0px;
}
.search-box:not(:valid) ~ .close-icon {
	display: none;
}

// loading
$radius: 10px;

@function delay($interval, $count, $index) {
  @return ($index * $interval) - ($interval * $count);
}


@keyframes ball-spin-fade-loader {
  50% {
    opacity: 0.3;
    transform: scale(0.4);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@mixin ball-spin-fade-loader($n:8, $start:1) {
  @for $i from $start through $n {
    > div:nth-child(#{$i}) {
      $iter: 360 / $n;
      $quarter: ($radius / 2) + ($radius / 5.5);

      @if $i == 1 {
        top: $radius;
        left: 0;
      } @else if $i == 2 {
        top: $quarter;
        left: $quarter;
      } @else if $i == 3 {
        top: 0;
        left: $radius;
      } @else if $i == 4 {
        top: -$quarter;
        left: $quarter;
      } @else if $i == 5 {
        top: -$radius;
        left: 0;
      } @else if $i == 6 {
        top: -$quarter;
        left: -$quarter;
      } @else if $i == 7 {
        top: 0;
        left: -$radius;
      } @else if $i == 8 {
        top: $quarter;
        left: -$quarter;
      }

      animation: ball-spin-fade-loader 1s delay(0.12s, $n, $i - 1) infinite linear;
    }
  }
}

.ball-spin-fade-loader {
  @include ball-spin-fade-loader();
  position: relative;
  left: -20px;
  top: 5px;

  > div {
    width: 5px;
    height: 5px;
    border-radius: 100%;
    margin: 2px;
    background-color: #fff;
    animation-fill-mode: both;
    position: absolute;
  }
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.modal {
  &-header {
    button {
      all: unset;
      font-size: 28px;
    }
  }
}